import Axios from "axios";
import {
  API_URL,
  callManagementConstants,
  loanConstants,
} from "../constants/ActionTypes";
import { alertActions } from "./alertAction";
import history from "../history";
import moment from "moment";
import axios from "axios";
import Cookies from "universal-cookie";
import apiAxios from "../api";
import { Role } from "../Roles";

const { REACT_APP_JAVA_API_URL } = process.env;
const Dialler_url = "https://dev-api.collekto.co.in/dialer/api";
const defaultOptions = {
  headers: { Authorization: "Bearer " + localStorage.getItem("token") },
};

const collektoUser = JSON.parse(localStorage.getItem("collektoUser"));
const { role, Client_Name, client } = collektoUser || {};

export const getActiveLoanAll = (objectId, page, role, state) => (dispatch) => {
  const roleType = role === "MANAGER" ? "managerId" : "collectorId";
  console.log("state in active loan action", state);
  let url =
    `${API_URL}/loans/active/loans` +
    `?${roleType}=${objectId}&page=${page}&gte=${state.startMonth}` +
    `&lte=${state.endMonth}&clientsName=${state.clientsName}&bucket=${state.bucket}`;
  return Axios.get(url, defaultOptions)
    .then((res) => {
      var data = res.data;
      const colldata = data[0]
        .map((t1) => ({ ...t1, ...data[1].find((t2) => t2.loanId === t1._id) }))
        .sort((a, b) => moment(a.disCreatedAt) - moment(b.disCreatedAt));
      const dispcolldata = data[1];
      dispatch({
        type: callManagementConstants.GET_ACTIVE_CALL,
        payload: colldata,
      });
      dispatch({
        type: callManagementConstants.GET_DISP_ACTIVE_CALL,
        payload: dispcolldata,
      });
    })
    .catch(handleError);
};

export const getActiveLoanAllFilters =
  (objectId, page, role, state) => async (dispatch) => {
    const roleType = role === "MANAGER" ? "managerId" : "collectorId";
    let url =
      `${API_URL}/loans/active/loans` +
      `?${roleType}=${objectId}&page=${page}&gte=${state.gte}` +
      `&lte=${state.lte}&clientsName=${state.clientsName}&bucket=${state.bucket}&disposition=${state.disposition_class}`;
    return await Axios.get(url, defaultOptions)
      .then((res) => {
        var data = res.data;
        const colldata = data[0]
          .map((t1) => ({
            ...t1,
            ...data[1].find((t2) => t2.loanId === t1._id),
          }))
          .sort((a, b) => moment(a.disCreatedAt) - moment(b.disCreatedAt));
        const dispcolldata = data[1];
        dispatch({
          type: callManagementConstants.GET_ACTIVE_CALL,
          payload: colldata,
        });
        dispatch({
          type: callManagementConstants.GET_DISP_ACTIVE_CALL,
          payload: dispcolldata,
        });
      })
      .catch(handleError);
  };

export const getDistClientBucket = () => async (dispatch) => {
  let data = await Axios.get(
    `${API_URL}/loans/getDistinctBucketClients`,
    defaultOptions
  )
    .then((res) => {
      var data = res.data;
      dispatch({
        type: callManagementConstants.GET_DIST_CLIENT_BUCKET,
        payload: data,
      });
    })
    .catch(handleError);
  return data;
};

export const getDistClientBucketGteLte = (state) => async (dispatch) => {
  let url = `${API_URL}/loans/getDistinctBucketClients?gte=${state.startMonth}&lte=${state.endMonth}`;
  let data = await Axios.get(url, defaultOptions)
    .then((res) => {
      var data = res.data;
      dispatch({
        type: callManagementConstants.GET_DIST_CLIENT_BUCKET,
        payload: data,
      });
    })
    .catch(handleError);
  return data;
};

export const getDistNotificationClient = () => async (dispatch) => {
  return apiAxios
    .get(`${API_URL}/notification/getDistNotificationClient`, defaultOptions)
    .then((res) => {
      var data = res.data;
      dispatch({
        type: callManagementConstants.GET_DIST_CLIENT_BUCKET,
        payload: data,
      });
    })
    .catch(handleError);
};
export const getSettlementLoanAll = (objectId, page, role) => (dispatch) => {
  const roleType = role === "MANAGER" ? "managerId" : "collectorId";
  return Axios.get(
    `${API_URL}/loans/settlement/loans?${roleType}=${objectId}&page=${page}&isSettlement=true`,
    defaultOptions
  )
    .then((res) => {
      var data = res.data;
      const colldata = data[0]
        .map((t1) => ({ ...t1, ...data[1].find((t2) => t2.loanId === t1._id) }))
        .sort((a, b) => moment(a.disCreatedAt) - moment(b.disCreatedAt));
      dispatch({
        type: callManagementConstants.GET_SETTLEMENT_CALL,
        payload: colldata,
      });
    })
    .catch(handleError);
};

// export const getSearchActiveLoan = (collectorId, createdBy, lte,gte,disposition_class) => dispatch => {
//     const apiurl = (createdBy === 'PARTNER')?'partnerloans':'loans'
//     return Axios.get(`${API_URL}/${apiurl}/active/loans?collectorId=${collectorId}&lte=${lte}&gte=${gte}&disposition_class=${disposition_class}`).then(res => {
//         dispatch({type : callManagementConstants.GET_ACTIVE_CALL, payload : res.data})
//     }).catch(handleError)
// }

// export const getPartnerLoanAll = (partnerId) => dispatch => {
//     return Axios.get(`${API_URL}/loans/by/partner?partnerId=${partnerId}`).then(res => {
//         dispatch({type : callManagementConstants.GET_PARTNER_ALL, payload : res.data})
//     }).catch(handleError)
// }

export const onCallMaintinance = (loanId) => (dispatch) => {
  //after orginate api call
  var postdata = {
    call_status: "CALL_STARTED",
    loanId: loanId,
    duration: "",
    recordlink: null,
  };

  Axios.post(`${API_URL}/call-disposition`, postdata, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.CREATE_CALL_STATUS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const sendSMS = (data) => (dispatch) => {
  Axios.post(`${API_URL}/call-disposition/send-sms`, data, defaultOptions)
    .then((res) => {
      alertActions.success(`sms sent successfully`);
    })
    .catch(handleError);
};

export const sendWhatsAppMessage = (data) => (dispatch) => {
  Axios.post(
    `${API_URL}/call-disposition/whatsapp-message`,
    data,
    defaultOptions
  )
    .then((res) => {
      alertActions.success(`whatsapp message sent successfully`);
    })
    .catch(handleError);
};
export const getProfileListApi = () => (dispatch) => {
  apiAxios
    .get(`${API_URL}/agentprofile/getList`, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.CREATE_PROFILE_LIST,
        payload: res.data,
      });
    })
    .catch(handleError);
};
export const getMonthsCalcApi =
  (from, to, collectorId, bucket, managerId, product, search) => (dispatch) => {
    bucket = encodeURI(bucket);
    Axios.get(
      `${API_URL}/agentprofile?from=${from}&to=${to}&search=${search}&collectorId=${collectorId}&bucket=${bucket}&managerId=${managerId}&product=${product}`,
      defaultOptions
    )
      .then((res) => {
        dispatch({
          type: callManagementConstants.CREATE_MONTHS_CALC_LIST,
          payload: res.data,
        });
      })
      .catch(handleError);
  };

export const getDailyCallReports =
  (collectorId, managerId, gte, lte) => (dispatch) => {
    if (!gte) {
      gte = moment().utc().format("YYYY-MM-DD");
    }
    if (!lte) {
      lte = moment().utc().add(1, "day").format("YYYY-MM-DD");
    }
    // gte =  '2023-09-01';
    // lte = '2023-09-30';
    const url =
      role === Role.CLIENT_ADMIN
        ? `${API_URL}/call-disposition/daily-reports?gte=${gte}&lte=${lte}&clientName=${Client_Name}`
        : role === Role.CUSTOMER
        ? `${API_URL}/call-disposition/daily-reports?gte=${gte}&lte=${lte}&clientName=${client}`
        : `${API_URL}/call-disposition/daily-reports?collectorId=${collectorId}&managerId=${managerId}`;
    return Axios.get(url, defaultOptions)
      .then((res) => {
        const sssresult = res.data.map(({ collectorId, ...object }) => ({
          Agent_Name: collectorId?.fullName,
          ...object,
        }));
        dispatch({
          type: callManagementConstants.DAILY_CALL_REPORTS,
          payload: sssresult,
        });
      })
      .catch(handleError);
  };

export const getDailyFieldAgentReports =
  (managerId, gte, lte) => (dispatch) => {
    if (!gte) {
      gte = moment().startOf("month").format("YYYY-MM-DD");
    }
    if (!lte) {
      lte = moment().endOf("month").format("YYYY-MM-DD");
    }
    return Axios.get(
      `${API_URL}/field-disposition/daily-reports?managerId=${
        role === Role.CLIENT_ADMIN
          ? ""
          : role === Role.CUSTOMER
          ? ""
          : managerId
      }&clientName=${
        role === Role.CLIENT_ADMIN
          ? Client_Name
          : role === Role.CUSTOMER
          ? client
          : ""
      }&gte=${gte}&lte=${lte}`,
      defaultOptions
    )
      .then((res) => {
        dispatch({
          type: callManagementConstants.DAILY_CALL_REPORTS,
          payload: res.data,
        });
      })
      .catch(handleError);
  };

export const onCallConnect = (calldata) => (dispatch) => {
  Axios.put(`${API_URL}/call-disposition/single`, calldata, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.UPDATE_CALL_STATUS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const onCallHangup = (data) => (dispatch) => {
  Axios.put(`${API_URL}/call-disposition/single`, data, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.UPDATE_CALL_STATUS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const onUpdateCallStatus = (data) => (dispatch) => {
  return Axios.put(`${API_URL}/call-disposition/single`, data, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.UPDATE_CALL_STATUS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const updateLoan = (data, createdBy) => (dispatch) => {
  return Axios.put(`${API_URL}/loans/single/loan`, data, defaultOptions)
    .then((res) => {
      dispatch({ type: loanConstants.GET_SINGLE_LOAN, payload: res.data });
      alertActions.success("updated successfully");
      // history.push(`/active-loans`)
    })
    .catch(handleError);
};

export const updateSettalementStatus = (data, createdBy) => (dispatch) => {
  return Axios.put(
    `${API_URL}/settlement/updateSettlementStatus`,
    data,
    defaultOptions
  )
    .then((res) => {
      dispatch({
        type: callManagementConstants.UPDATE_SETTLEMENT_STATUS,
        payload: res.data,
      });
      alertActions.success("updated successfully");
      // history.push(`/active-loans`)
      let path = `/notifications`;
      setTimeout(() => {
        history.push(path);
        // this.props.history.push(path);
      }, 3000);
    })
    .catch(handleError);
};

// export const downloadSupportingDocument = (data, createdBy) => dispatch => {
//     return Axios.get(`${API_URL}/settlement/supportingDocument`).then(res => {
//         dispatch({ type: callManagementConstants.DOWNLOAD_DOC, payload: res.data })
//         alertActions.success('updated successfully')
//         // history.push(`/active-loans`)
//         let path = `/notifications`;
//         setTimeout(() => {
//             history.push(path)
//             // this.props.history.push(path);
//         }, 3000)
//     }).catch(handleError)
// }
export const updateSettalementStatusbyClient =
  (data, createdBy) => (dispatch) => {
    return Axios.put(
      `${API_URL}/settlement/approveByClient`,
      data,
      defaultOptions
    )
      .then((res) => {
        dispatch({
          type: callManagementConstants.UPDATE_SETTLEMENT_CLIENTSTATUS,
          payload: res.data,
        });
        alertActions.success("updated successfully");
        let path = `/notifications`;
        setTimeout(() => {
          history.push(path);
          // this.props.history.push(path);
        }, 3000);
        // history.push(`/active-loans`)
      })
      .catch(handleError);
  };

export const approveByClient = (data, createdBy) => (dispatch) => {
  return Axios.put(
    `${API_URL}/settlement/approveByClient`,
    data,
    defaultOptions
  )
    .then((res) => {
      dispatch({
        type: callManagementConstants.UPDATE_SETTLEMENT_CLIENTSTATUS,
        payload: res.data,
      });
      alertActions.success("updated successfully");
      // history.push(`/active-loans`)
      let path = `/notifications`;
      setTimeout(() => {
        history.push(path);
        // this.props.history.push(path);
      }, 3000);
    })
    .catch(handleError);
};

export const updateOldData = (username) => (dispatch) => {
  return Axios.put(
    `${API_URL}/predictive-call/old-data/deactivate?username=${username}`,
    {},
    defaultOptions
  )
    .then((res) => {})
    .catch(handleError);
};

// const createAlert = (loanId, callDispositionId, alertType, alertDate, alertTime) => async (dispatch) => {
//   console.log("creating the alert...");
//   await Axios.post(`https://dev-api.collekto.co.in/api/v2/alerts`, {loanId, callDispositionId, alertType, alertDate, alertTime}).then(res=>{
//     dispatch({type: callManagementConstants.CREATE_ALERT, payload: res.data});
//   }).catch(handleError);
// }

export const getCallHistory = (loanId) => (dispatch) => {
  return Axios.get(
    `${API_URL}/call-disposition/caseHistory?loanId=${loanId}`,
    defaultOptions
  )
    .then((res) => {
      dispatch({
        type: callManagementConstants.GET_CALL_HISTORY,
        payload: res.data,
      });
      console.log(res,"ress");
      return res; // Return the response data
    })
    .catch((error) => {
      handleError(error); // Handle errors
      throw error; // Rethrow the error to propagate it to the caller
    });
};


export const getCallDispositionById = (id) => (dispatch) => {
  console.log(id, "dispId");
  return Axios.get(`${API_URL}/call-disposition/id/${id}`, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.GET_CALL_DISPOSITION,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getDispositionWithCode = () => (dispatch) => {
  return apiAxios
    .get(`${API_URL}/disposition/with/code`, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.GET_DISPOSITION_CODE,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getAllocationReports = () => async (dispatch) => {
  return Axios.get(
    `${API_URL}/call-disposition/allocation-reports?clientName=${
      role === Role.CLIENT_ADMIN
        ? Client_Name
        : role === Role.CUSTOMER
        ? client
        : ""
    }`,
    defaultOptions
  )
    .then((res) => {
      var data = res.data;
      const colldata = data[0]
        .map((t1) => ({ ...t1, ...data[1].find((t2) => t2.loanId === t1._id) }))
        .sort((a, b) => moment(a.disCreatedAt) - moment(b.disCreatedAt));
      dispatch({
        type: callManagementConstants.GET_ALLOCATION_REPORTS,
        payload: colldata,
      });
    })
    .catch(handleError);
};

export const getAdditionalReports = (state) => async (dispatch) => {
  return Axios.get(
    `${API_URL}/call-disposition/additionalreports?gte=${state.gte}&lte=${
      state.lte
    }&clientName=${
      role === Role.CLIENT_ADMIN
        ? Client_Name
        : role === Role.CUSTOMER
        ? client
        : ""
    }`,
    defaultOptions
  )
    .then((res) => {
      var data = res.data;
      dispatch({
        type: callManagementConstants.GET_ADDITIONAL_REPORTS,
        payload: data,
      });
    })
    .catch(handleError);
};

export const getRedirectApi = () => (dispatch) => {
  return apiAxios
    .post(`${API_URL}/redirectBi`, "", defaultOptions)
    .then((res) => {
      console.log("seeted cookie is", res.headers);
      const cookies = new Cookies();
      console.log("res.data: ", res.data.name);
      if (res.data.name != "Error") {
        let cookie = res.data.cookies[0];
        cookie = cookie.split("session=");
        cookie = cookie[1].split(";");
        cookie = cookie[0];
        cookies.set("session", cookie, { path: "/", domain: "collekto.co.in" });
      }
      let subsType = "primary_explorar";
      if (
        res.data?.response?.subscription_type &&
        res.data?.response?.subscription_type == "secondry"
      ) {
        subsType = "secondry";
      }
      dispatch({
        type: callManagementConstants.subscriptionType,
        payload: subsType,
      });
    });
};

export const getWorkableReports = (gte, lte) => async (dispatch) => {
  if (!gte) {
    gte = moment().startOf("month").format("YYYY-MM-DD");
  }
  if (!lte) {
    lte = moment().endOf("month").format("YYYY-MM-DD");
  }
  return Axios.get(
    `${API_URL}/call-disposition/getDispositionReport?gte=${gte}&lte=${lte}&clientName=${
      role === Role.CLIENT_ADMIN
        ? Client_Name
        : role === Role.CUSTOMER
        ? client
        : ""
    }`,
    defaultOptions
  )
    .then((res) => {
      if (res?.data?.error) {
        return;
      }
      dispatch({
        type: callManagementConstants.GET_WORKABLE_REPORTS,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getDispositionAll = () => (dispatch) => {
  return apiAxios
    .get(`${API_URL}/disposition/all`, defaultOptions)
    .then((res) => {
      dispatch({
        type: callManagementConstants.GET_DISPOSITION_ALL,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const deleteDisposition = (data) => (dispatch) => {
  return Axios.delete(`${API_URL}/call-disposition`, defaultOptions)
    .then((res) => {
      console.log(`res `, res);
      dispatch({
        type: callManagementConstants.DELETE_CALL_DISPOSITION,
        payload: data,
      });
    })
    .catch(handleError);
};

export const updateDisposition = (data) => (dispatch) => {
  console.log(`data `, data);
  const formData = new FormData();
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      formData.append([key], data[key]);
    }
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  return Axios.put(`${API_URL}/call-disposition/single`, formData, config)
    .then((res) => {
      alertActions.success("collector updated successfully");
      // dispatch({type : callManagementConstants.UPDATE_CALL_STATUS, payload : res.data})
      // const { loanId } = data.loanId
      console.log(`res.data `, res.data);
      history.push(`/view-loan`, { loanId: data.loanId, Loan_ID: data.Loan_ID });
      return res.data;
    })
    .catch(handleError);
};

export const saveState = (data) => {
  return {
    type: callManagementConstants.SEARCH_HISTORY,
    payload: data,
  };
};

export const createDisposition = (data, createdBy) => (dispatch) => {
  const formData = new FormData();
  console.log(data);
  for (const key in data) {
    if (key === "screenShot" || key === "supportingDoc") {
      const files = data[key];
      files.forEach((el) => {
        formData.append(key, el);
      });
    } else if (data.hasOwnProperty(key)) {
      formData.append([key], data[key]);
    }
  }

  console.log("FormData after appending data:", formData);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
      enctype: "multipart/form-data",
    },
  };
  const userStorageData = JSON.parse(localStorage.getItem("collektoUser"));
  const myOptions = {
    headers: {
      ...defaultOptions.headers,
      collectorId: collektoUser.role === "COLLECTOR" ? collektoUser?._id : "",
    },
  };
  console.log(formData, "fData");
  Axios.post(`${API_URL}/call-disposition`, formData, config)
    .then(async (res) => {
      console.log(`res.data call disposition`, res.data);
      alertActions.success("Disposition Added Successfully");
      let {
        loanId,
        dispositionId,
        _id,
        disposition_class,
        date: alertDate,
        time: alertTime,
      } = res.data;
      if (!dispositionId) {
        dispositionId = _id;
      }
      // axios
      //   .post(`${Dialler_url}/call/disposition`, {
      //     value: "N",
      //     agent_user: userStorageData.username,
      //   })
      //   .then((res) => {
      //     console.log(`call hangoup `, res.data);
      //   })
      //   .catch((error) => {
      //     console.log(`hang error `, error);
      //   });
      if (disposition_class === "PTP" || disposition_class === "CALLBACK") {
        disposition_class =
          disposition_class === "CALLBACK" ? "CallBack" : "PTP";
        alertDate = moment(alertDate).format("YYYY-MM-DD");
        await Axios.post(
          `${REACT_APP_JAVA_API_URL}/api/v2/alerts`,
          {
            loanId,
            dispositionId,
            alertType: disposition_class,
            alertDate,
            alertTime,
          },
          myOptions
        ).catch(handleError);
      }
      // await fetch("https://dev-api.collekto.co.in/api/v2/alerts", {
      //   "headers": {
      //     'Content-Type': "application/json",
      //     "authorization": "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWVhYzEyNzU1OTc1ZDA2M2UzYzE5MDkiLCJyb2xlIjoiY29sbGVjdG9yIiwiaWF0IjoxNjgzODg1NzMxfQ.3931tyrCtbMLBXYkVXWRRTU4o0e7U6w1POWVrhYIg_4",
      //   },
      //   "body": JSON.stringify({loanId, callDispositionId: dispositionId, alertType:disposition_class, alertDate, alertTime}),
      //   "method": "POST",
      //   "mode": "cors",
      // });
      if (data.isPredictive) {
        dispatch({ type: loanConstants.GET_SINGLE_LOAN, payload: {} });
        dispatch({
          type: callManagementConstants.PREDICTIVE_CALL_DISPOSTION,
          payload: 1,
        });
        // getPrediciveLoanByLoanId(username)
      } else {
        console.log(`dispatch action`, res.data);
        // const { data : {loanId} } = res
        // let aa = [res.data]
        // let result = aa.map(({ loanId, ...object}) => ({loanId : loanId._id, ...loanId, ...object}));
        // console.log(`dispatch result`, result)
        // const { loanId } = data

        dispatch({
          type: callManagementConstants.CREATE_CALL_STATUS,
          payload: res.data,
        });
        // alertActions.success('Call Details updated successfully')
        ///alert.show("Alert test")
        // alertActions.success("Disposition Added Successfully");
        history.push(`/active-loans`);
      }
    })
    .catch(handleError);
};

export const getBorrowerProfileData = (username) => (dispatch) => {
  return apiAxios
    .get(
      `${API_URL}/borrowerProfile/getBorrowerProfile?loanId=${username}`,
      defaultOptions
    )
    .then((res) => {
      dispatch({
        type: callManagementConstants.BORROWER_PROFILE,
        payload: res.data,
      });
    });
};

export const getPrediciveLoanByLoanId = (username) => async (dispatch) => {
  console.log(`getPrediciveLoanByLoanId`);
  // const apiurl = (createdBy === 'PARTNER')?'partnerloans':'loans'
  return await Axios.get(
    `${API_URL}/predictive-call/get-loan/by/mobile?username=${username}`,
    defaultOptions
  )
    .then((res) => {
      console.log(`getPrediciveLoanByLoanId res.data `, res.data);
      if (res.data) {
        console.log(`dispatch 0`, res.data);
        var predData = res.data;
        res.convox_id = predData.convoxid;
        res.dialledNumber = predData.dialledNumber;
        // getCallHistory(res.data._id)
        Axios.get(
          `${API_URL}/call-disposition/by/loan?loanId=${res.data?.Loan_ID}`,
          defaultOptions
        ).then((res) => {
          res.convox_id = predData.convoxid;
          res.dialledNumber = predData.dialledNumber;
          dispatch({
            type: callManagementConstants.GET_CALL_HISTORY,
            payload: res.data,
          });
        });
        // dispatch({
        //   type: callManagementConstants.GET_CALL_HISTORY,
        //   payload: res.data,
        // });

        dispatch({ type: loanConstants.GET_SINGLE_LOAN, payload: res.data });
        dispatch({
          type: callManagementConstants.PREDICTIVE_CALL_DISPOSTION,
          payload: 0,
        });
      } else {
        console.log(`dispatch 1`);
        dispatch({ type: loanConstants.GET_SINGLE_LOAN, payload: {} });
        dispatch({
          type: callManagementConstants.GET_CALL_HISTORY,
          payload: {},
        });
        dispatch({
          type: callManagementConstants.PREDICTIVE_CALL_DISPOSTION,
          payload: 1,
        });
      }
    })
    .catch(handleError);
};

// all notifications
export const getNotificationAll =
  (objectId, page, role, state) => (dispatch) => {
    const roleType =
      role === "MANAGER"
        ? "managerId"
        : role === "COLLECTOR"
        ? "collectorId"
        : "client";
    const urlType =
      role === "MANAGER"
        ? "getByManagerId"
        : role === "COLLECTOR"
        ? "getByCollectorId"
        : "getByClientUsername";
    return Axios.get(
      `${API_URL}/notification/${urlType}?${roleType}=${objectId}&startMonth=${state?.startMonth}&endMonth=${state?.endMonth}&clientsName=${state?.clientsName}`,
      defaultOptions
    )
      .then((res) => {
        var data = res.data;
        const colldata = data
          .map((t1) => ({ ...t1, ...data.find((t2) => t2.loanId === t1._id) }))
          .sort((a, b) => moment(a.disCreatedAt) - moment(b.disCreatedAt));
        const notificationData = colldata.map((obj) => {
          console.log("", obj.createdAt);

          obj.createdAt = `${moment(obj.createdAt).format("DD/MM/YYYY")}`;
          return obj;
        });
        dispatch({
          type: callManagementConstants.GET_NOTIFICATION_ALL,
          payload: notificationData,
        });
      })
      .catch(handleError);
  };

export const getNotificationAllNew = (objectId, page, role) => (dispatch) => {
  const roleType =
    role === "MANAGER"
      ? "managerId"
      : role === "COLLECTOR"
      ? "collectorId"
      : "client";
  const urlType =
    role === "MANAGER"
      ? "getByManagerId"
      : role === "COLLECTOR"
      ? "getByCollectorId"
      : "getByClientUsername";
  Axios.get(
    `${API_URL}/notification/${urlType}?${roleType}=${objectId}`,
    defaultOptions
  )
    .then((res) => {
      var data = res.data;
      console.log(data);
    })
    .catch(handleError);
};
export const getDescriptiveReports =
  (objectId, page, role, state) => (dispatch) => {
    const roleType =
      role === "MANAGER"
        ? "managerId"
        : role === "COLLECTOR"
        ? "collectorId"
        : role === "ADMIN"
        ? "admin"
        : "client";
    const urlType =
      role === "MANAGER"
        ? "getManagerSettlement"
        : role === "COLLECTOR"
        ? "getCollectorSettlement"
        : role === "ADMIN"
        ? "getAdminSettlement"
        : role === "CLIENT_ADMIN"
        ? "getClientAdminSettlement"
        : "getClientSettlement";

    // const url = `${API_URL}/settlement/${urlType}?${roleType}=${objectId}&clientName=${Client_Name}&gte=${state.gte_noti}&lte=${state.lte_noti}`;
    return Axios.get(
      `${API_URL}/settlement/${urlType}?${roleType}=${objectId}&clientName=${Client_Name}&gte=${state.gte_noti}&lte=${state.lte_noti}`,
      defaultOptions
    )
      .then((res) => {
        var data = res.data;
        let colldata = data.map((t1) => ({
          ...t1,
          ...data.find((t2) => t2.loanId === t1._id),
        }));
        colldata = colldata || [];
        colldata.sort(
          (a, b) => moment(a.disCreatedAt) - moment(b.disCreatedAt)
        );
        dispatch({
          type: callManagementConstants.GET_REPORTS_ALL,
          payload: colldata,
        });
      })
      .catch(handleError);
  };

export const getClientsByManager = (managerId) => (dispatch) => {
  console.log(`managerId`, managerId);
  return Axios.get(
    `${API_URL}/settlement/clientsByManagerID?managerId=${managerId}`,
    defaultOptions
  )
    .then((res) => {
      dispatch({
        type: callManagementConstants.GET_CLIENTS_BY_MANAGER,
        payload: res.data,
      });
    })
    .catch(handleError);
};

export const getManagerReports = (client, managerId) => (dispatch) => {
  return Axios.get(
    `${API_URL}/settlement/getManagerReports?managerId=${managerId}&client=${client}`,
    defaultOptions
  )
    .then((res) => {
      dispatch({
        type: callManagementConstants.GET_MANAGER_REPORTS,
        payload: res.data,
      });
      console.log(`all table data overall `, res.data);
    })
    .catch(handleError);
};

export const deleteNotification = (loanId) => (dispatch) => {
  console.log("deleteLoan,", defaultOptions);
  return Axios.put(
    `${API_URL}/notification/deactivate?_id=${loanId}&isActive=false`,
    defaultOptions
  ).then((res) => {
    dispatch({
      type: callManagementConstants.DELETE_NOTIFICATION,
      payload: loanId,
    });
    alertActions.success("Notification deleted successfully");
  });
};

export const deleteNotificationApprove = (loanId) => (dispatch) => {
  console.log("deleteLoan,", defaultOptions);
  return Axios.put(
    `${API_URL}/notification/manager/deactivate?settalementId=${loanId}&isActive=false`,
    defaultOptions
  ).then((res) => {
    dispatch({
      type: callManagementConstants.DELETE_NOTIFICATION_APPROVE,
      payload: loanId,
    });
    alertActions.success("Notification deleted successfully");
  });
};

export const letterUpload = (data, letter) => (dispatch) => {
  console.log(`uploaddata `, data, letter);
  const settlementId = data._id;
  const formData = new FormData();
  formData.append("letter", letter);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };
  Axios.put(
    `${API_URL}/settlement/uploadLetter?id=${settlementId}`,
    formData,
    config
  )
    .then((res) => {
      dispatch({
        type: callManagementConstants.LETTER_UPLOAD,
        payload: res.data,
      });
      alertActions.success("updated successfully");
      console.log(`res.data `, res.data);
      let path = `/notifications`;
      setTimeout(() => {
        history.push(path);
      }, 3000);
    })
    .catch(handleError);
};

export const getLetterDownload = (data) => (dispatch) => {
  console.log(data);
  const settlementId = data;
  return Axios.get(
    `${API_URL}/settlement/downloadLetter?id=${settlementId}`,
    defaultOptions
  )
    .then((res) => {
      dispatch({
        type: callManagementConstants.GET_LETTER_DOWNLOAD,
        payload: res.data,
      });
      console.log(`all table data overall `, res.data);
    })
    .catch(handleError);
};

// export const getNotificationAll = () => dispatch => {
//     return Axios.get(`${API_URL}/notification/all`, defaultOptions).then(res => {
//         dispatch({ type: callManagementConstants.GET_NOTIFICATION_ALL, payload: res.data })
//     }).catch(handleError)
// }

export const handleError = (error) => {
  console.log(`error `, error);
  alertActions.error(error);
};
