import { TenantConstant } from "../constants/ActionTypes";

const initState = {
  tenants: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case TenantConstant.GET_TENANT_ALL:
      return { tenants: action.payload };

    default:
      return state;
  }
};
