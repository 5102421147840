export const agentMonitor = {
  getAgentMonitorDataUrl: "/api/v2/dialer/agentStatus",
  getAgentMonitorStatusUrl: "/api/v2/dialer/agent/agentStatus",
};

export const TenantOnboarding = {
  createTenantUrl: "/api/v2/profile/tenants",
  getTenantUrl: "/api/v2/profile/tenants",
  active_inactive_url: "/api/v2/profile/tenants/active",
};
