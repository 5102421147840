import axios from "axios";
import { agentMonitor } from "./constantApiUrls";
import { JAVA_API_URL } from "../../../constants/ActionTypes";

const { REACT_APP_JAVA_API_URL } = process.env;

const getAgentMonitorData = async (managerId) => {
  try {
    return await axios.get(
      `${REACT_APP_JAVA_API_URL}${agentMonitor.getAgentMonitorDataUrl}`,
      {
        headers: {
          managerId,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in get agent monitor data api ", error.response);
  }
};
const getAgentMonitorStatus = async (username) => {
  try {
    console.log(username,"collecto username");
    return await axios.get(
      `${JAVA_API_URL}/api/v2${agentMonitor.getAgentMonitorStatusUrl}`,
      {
        headers: {
          username,
        },
      }
    );
  } catch (error) {
    if (error.response)
      console.log("error in get agent monitor status api ", error.response);
  }
};

const CommonApi = {
  getAgentMonitorData,
  getAgentMonitorStatus
};

export default CommonApi;
