// export const API_URL = process.env.REACT_APP_API_URL
const { REACT_APP_API_URL, REACT_APP_JAVA_API_URL } = process.env;
export const API_URL = REACT_APP_API_URL;
export const JAVA_API_URL = REACT_APP_JAVA_API_URL;
// export const API_URL = 'http://localhost:8000/api/v1'
// export const API_URL = 'http://148.66.129.6:8000/api/v1'
// console.log(`REACT_APP_API_URL `, REACT_APP_API_URL)
export const customerConstants = {
  GET_CUSTOMER_ALL: "GET_CUSTOMER_ALL",
  GET_CUSTOMER: "GET_CUSTOMER",
  UPDATE_CUSTOMER: "UPDATE_CUSTOMER",
  DELETE_CUSTOMER: "DELETE_CUSTOMER",
};

export const targetConstants = {
  GET_TARGET_BY_COLLECTOR: "GET_TARGET_BY_COLLECTOR",
  GET_GROUP_BY_CLIENT: "GET_GROUP_BY_CLIENT",
  GET_CITY: "GET_CITY",
  GET_REGION: "GET_REGION",
  GET_BUCKET: "GET_BUCKET",
};

export const backupConstants = {
  GET_BACKUP_ALL: "GET_BACKUP_ALL",
};

export const historyConstants = {
  CLEAR_HISTORY: "CLEAR_HISTORY",
};

export const analyticalConstants = {
  GET_OVERALL_ANALYTICS: "GET_OVERALL_ANALYTICS",
  GET_WEIGHTED_DATA: "GET_WEIGHTED_DATA",
  DESELECT_COLLECTOR_ANALYTICS: "DESELECT_COLLECTOR_ANALYTICS",
  CLEAR_COLLECTOR_ANALYTICS: "CLEAR_COLLECTOR_ANALYTICS",
  GET_OVERALL_TEST_ANALYTICS: "GET_OVERALL_TEST_ANALYTICS",
  GET_OVERALL_BY_MANAGER: "GET_OVERALL_BY_MANAGER",
  LAST_MONTH_AMOUNT: "LAST_MONTH_AMOUNT",
  BEST_GROUPBY_MONTH_AMOUNT: "BEST_GROUPBY_MONTH_AMOUNT",
  BEST_MONTH_AMOUNT: "BEST_MONTH_AMOUNT",
  BEST_MONTH_MANAGER: "BEST_MONTH_MANAGER",
  ENCORE_REPORT: "ENCORE_REPORT",
  GET_ALLOCATION_TABLE: "GET_ALLOCATION_TABLE",
  LAST_INTERACTION_REPORTS: "LAST_INTERACTION_REPORTS",
  GET_RETAINED_REPORT: "GET_RETAINED_REPORT",
  GET_LOGIN_REPORT: "GET_LOGIN_REPORT",
};

export const userConstants = {
  GET_USER_ALL: "GET_USER_ALL",
  GET_USER: "GET_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
};
export const contactConstants = {
  GET_CONTACT_ALL: "GET_CONTACT_ALL",
};
export const dataVisualizationConstants = {
  GET_VISUALIZATION_ALL: "GET_VISUALIZATION_ALL",
  GET_VISUALIZATION: "GET_VISUALIZATION",
  ADD_VISUALIZATION: "ADD_VISUALIZATION",
  UPDATE_VISUALIZATION: "UPDATE_VISUALIZATION",
  DELETE_VISUALIZATION: "DELETE_VISUALIZATION",
};

export const adminConstants = {
  LOGIN_SUCCESS: "ADMIN_LOGIN_SUCCESS",
  LOGIN_FAILURE: "ADMIN_LOGIN_FAILURE",
  MY_PROFILE: "ADMIN_MY_PROFILE",
  LOGOUT: "USERS_LOGOUT",
  GET_ADMINS: "GET_ADMIN_ALL_ADMIN",
  DELETE_ADMIN: "DELETE_ADMIN_DETAILS",
  DASHBOARD: "DASHBOARD",
  START_SPINNER: "START_SPINNER",
  STOP_SPINNER: "STOP_SPINNER",
};
export const bucketConstants = {
  GET_BUCKET_ALL: "GET_BUCKET_ALL",
  GET_CALLED_DISPOSITION_ALL: "GET_CALLED_DISPOSITION_ALL",
};

export const employeeConstants = {
  GET_EMPLOYEE_ALL: "GET_EMPLOYEE_ALL",
  GET_EMPLOYEE: "GET_EMPLOYEE",
  ADD_EMPLOYEE: "ADD_EMPLOYEE",
  UPDATE_EMPLOYEE: "UPDATE_EMPLOYEE",
  DELETE_EMPLOYEE: "DELETE_EMPLOYEE",
};

export const collectorConstants = {
  GET_COLLECTOR_ALL: 'GET_COLLECTOR_ALL',
  GET_ATTENDANCE: 'GET_ATTENDANCE',
  GET_COLLECTOR: 'GET_COLLECTOR',
  ADD_COLLECTOR: 'ADD_COLLECTOR',
  UPDATE_COLLECTOR: 'UPDATE_COLLECTOR',
  DELETE_COLLECTOR: 'DELETE_COLLECTOR',
  COLLECTOR_ALLOCATED_COUNT: 'COLLECTOR_ALLOCATED_COUNT',
  GET_MISSED_ALERTS: "GET_MISSED_ALERTS",
  GET_ALERTS_COUNT: "GET_ALERTS_COUNT",
  GET_ALERTS_ALL: "GET_ALERTS_ALL",
  SHOWALERTSMODAL: "SHOWALERTSMODAL",
  GET_NOTIFICATION_COUNT: "GET_NOTIFICATION_COUNT",
  SET_MODAL: "SET_MODAL",
  GET_COLLECTOR_PROCESS: "GET_COLLECTOR_PROCESS"
}

export const partnerConstants = {
  GET_PARTNER_ALL: "GET_PARTNER_ALL",
  GET_PARTNER: "GET_PARTNER",
  ADD_PARTNER: "ADD_PARTNER",
  UPDATE_PARTNER: "UPDATE_PARTNER",
  DELETE_PARTNER: "DELETE_PARTNER",
};
export const loanactiveTotalConstants = {
  LOAN_ACTIVE_TOTAL: "LOAN_ACTIVE_TOTAL",
};
export const successFeesConstants = {
  GET_SUCCESS_FEES_ALL: "GET_SUCCESS_FEES_ALL",
};

export const clientConstants = {
  GET_CLIENT_ALL: "GET_CLIENT_ALL",
  TRIGGER_UPDATE: "TRIGGER_UPDATE",
  GET_CLIENT: "GET_CLIENT",
  ADD_CLIENT: "ADD_CLIENT",
  UPDATE_CLIENT: "UPDATE_CLIENT",
  DELETE_CLIENT: "DELETE_CLIENT",
  ADD_CLIENT_PROFILE: "ADD_CLIENT_PROFILE",
  GET_UPLOADED_POLICIES: "GET_UPLOADED_POLICIES",
  NEW_GET_CLIENT_ALL: "NEW_GET_CLIENT_ALL",
  UPDATE_CLIENT_PROFILE: "UPDATE_CLIENT_PROFILE",
  ADD_SINGLE_PORTFOLIO: "ADD_SINGLE_PORTFOLIO",
  ADD_SINGLE_CLIENT: "ADD_SINGLE_CLIENT",
  GET_INCENTIVE_STRUCTURE: "GET_INCENTIVE_STRUCTURE",
  UPDATE_INCENTIVE_STRUCTURE: "UPDATE_INCENTIVE_STRUCTURE",
};
export const userDataConstants = {
  GET_USERDATA_ALL: "GET_USERDATA_ALL",
  GET_USERDATA: "GET_USERDATA",
  ADD_USERDATA: "ADD_USERDATA",
  UPDATE_USERDATA: "UPDATE_USERDATA",
  DELETE_USERDATA: "DELETE_USERDATA",
};

export const profileConstants = {
  GET_PROFILE_ALL: "GET_PROFILE_ALL",
  GET_PROFILE: "GET_PROFILE",
  ADD_PROFILE: "ADD_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
};

export const callManagementConstants = {
  GET_ACTIVE_CALL: "GET_ACTIVE_CALL",
  GET_DISP_ACTIVE_CALL: "GET_DISP_ACTIVE_CALL",
  GET_DIST_CLIENT_BUCKET: "GET_DIST_CLIENT_BUCKET",
  GET_PARTNER_ALL: "GET_PARTNER_ALL",
  UPDATE_CALL_STATUS: "UPDATE_CALL_STATUS",
  CREATE_CALL_STATUS: "CREATE_CALL_STATUS",
  GET_CALL_HISTORY: "GET_CALL_HISTORY",
  BORROWER_PROFILE: "BORROWER_PROFILE",
  GET_CALL_DISPOSITION: "GET_CALL_DISPOSITION",
  UPDATE_SINGLE_LOAN: "UPDATE_SINGLE_LOAN",
  UPDATE_SETTLEMENT_STATUS: "UPDATE_SETTLEMENT_STATUS",
  UPDATE_SETTLEMENT_CLIENTSTATUS: "UPDATE_SETTLEMENT_CLIENTSTATUS",
  LETTER_UPLOAD: "LETTER_UPLOAD",
  GET_DISPOSITION_CODE: "GET_DISPOSITION_CODE",
  CREATE_PROFILE_LIST: "CREATE_PROFILE_LIST",
  CREATE_MONTHS_CALC_LIST: "CREATE_MONTHS_CALC_LIST",
  GET_DISPOSITION_ALL: "GET_DISPOSITION_ALL",
  GET_ALLOCATION_REPORTS: "GET_ALLOCATION_REPORTS",
  GET_ADDITIONAL_REPORTS: "GET_ADDITIONAL_REPORTS",
  GET_WORKABLE_REPORTS: "GET_WORKABLE_REPORTS",
  subscriptionType: "subscriptionType",
  GET_CLIENTS_BY_MANAGER: "GET_CLIENTS_BY_MANAGER",
  GET_MANAGER_REPORTS: "GET_MANAGER_REPORTS",
  GET_LETTER_DOWNLOAD: "GET_LETTER_DOWNLOAD",
  DELETE_CALL_DISPOSITION: "DELETE_CALL_DISPOSITION",
  DAILY_CALL_REPORTS: "DAILY_CALL_REPORTS",
  PREDICTIVE_CALL_DISPOSTION: "PREDICTIVE_CALL_DISPOSTION",
  SEARCH_HISTORY: "SEARCH_HISTORY",
  DOWNLOAD_DOC: "DOWNLOAD_DOC",
  GET_NOTIFICATION_ALL: "GET_NOTIFICATION_ALL",
  CURRENT_PAGE: "CURRENT_PAGE",
  TOATAL_ITEMS: "TOATAL_ITEMS",
  TOATAL_PAGES: "TOATAL_PAGES",
  PER_PAGE: "PER_PAGE",
  GET_REPORTS_ALL: "GET_REPORTS_ALL",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  DELETE_NOTIFICATION_APPROVE: "DELETE_NOTIFICATION_APPROVE",
  CREATE_ALERT: "CREATE_ALERT",
};

export const portfolioConstants = {
  GET_PORTFOLIO_ALL: "GET_PORTFOLIO_ALL",
  GET_PORTFOLIO: "GET_PORTFOLIO",
  ADD_PORTFOLIO: "ADD_PORTFOLIO",
  UPDATE_PORTFOLIO: "UPDATE_PORTFOLIO",
  DELETE_PORTFOLIO: "DELETE_PORTFOLIO",
};

export const managerConstants = {
  GET_MANAGER_ALL: "GET_MANAGER_ALL",
  GET_MANAGER: "GET_MANAGER",
  ADD_MANAGER: "ADD_MANAGER",
  UPDATE_MANAGER: "UPDATE_MANAGER",
  DELETE_MANAGER: "DELETE_MANAGER",
  GET_NOTIFICATION_COUNT: "GET_NOTIFICATION_COUNT",
};

export const loanConstants = {
  GET_MANAGER_ANALYTICS: "GET_MANAGER_ANALYTICS",
  GET_MANAGER_PTPs: "GET_MANAGER_PTPs",
  DAILY_CALL_ACTIVITY: "DAILY_CALL_ACTIVITY",
  GET_MANAGER_FIELDAGENTS: "GET_MANAGER_FIELDAGENTS",
  ADD_LOAN_FILE_UPLOAD: "ADD_LOAN_FILE_UPLOAD",
  ADD_LOANS: "ADD_LOANS",
  GET_ALL_LOANS: "GET_ALL_LOANS",
  GET_MANAGER_FIELDAGENTS: "GET_MANAGER_FIELDAGENTS",
  subscriptionType: "subscriptionType",
  GET_EXTENSION_LOANS: "GET_EXTENSION_LOANS",
  GET_VERIFY_LOANS: "GET_VERIFY_LOANS",
  GET_MATCHING_LOANS: "GET_MATCHING_LOANS",
  VERIFIED_LOANS: "VERIFIED_LOANS",
  UPLOAD_COUNT: "UPLOAD_COUNT",
  GET_SINGLE_LOAN: "GET_SINGLE_LOAN",
  GET_SINGLE_STLMNT_LOAN: "GET_SINGLE_STLMNT_LOAN",
  LOAN_DELETE: "LOAN_DELETE",
  LOAN_ACTIVE_TOTAL: "LOAN_ACTIVE_TOTAL",
  SHOW_CONFIRM_MODEL: "SHOW_CONFIRM_MODEL",
  DELETE_MANAGER: "DELETE_MANAGER",
  LOAN_ACTIVITY: "LOAN_ACTIVITY",
  DELETE_LOAN: "DELETE_LOAN",
  DELETE_SHEET: "DELETE_SHEET",
  LOAN_HISTORY: "LOAN_HISTORY",
  LOADING_FALSE: "LOADING_FALSE",
  LOAN_REVOKE_EXTEN: "LOAN_REVOKE_EXTEN",
  GET_RULE_ENGINE_LOANS: "GET_RULE_ENGINE_LOANS",
  GET_SCHEDULED_LOANS: "GET_SCHEDULED_LOANS",
  GET_SCHEDULED_LOANS_ALL: "GET_SCHEDULED_LOANS_ALL",
  GET_CLIENTS_LIST: "GET_CLIENTS_LIST",
  GET_TARGETS_MANAGER: "GET_TARGETS_MANAGER",
  GET_MINBAR_GRAPH: "GET_MINBAR_GRAPH",
  GET_MAXBAR_GRAPH: "GET_MAXBAR_GRAPH",
  GET_LANDING_ANALYTICS: "GET_LANDING_ANALYTICS",
  // GET_LANDING_ANALYTICS: "GET_LANDING_ANALYTICS",
  GET_FIELD_CASES: "GET_FIELD_CASES",
  GET_CLIENT_INCENTIVES_RANGE: "GET_CLIENT_INCENTIVES_RANGE",
  CLEAR_ALL_LOANS: "CLEAR_ALL_LOANS",
};

export const partnerloanConstants = {
  GET_PARTNER_ALL_LOANS: "GET_PARTNER_ALL_LOANS",
  GET_PARTNER_EXTENSION_LOANS: "GET_PARTNER_EXTENSION_LOANS",
};

export const dataInsightConstants = {
  GET_DATA_INSIGHTS: "GET_DATA_INSIGHTS",
  GET_GROUP_ATTRIBUTES: "GET_GROUP_ATTRIBUTES",
  GET_DATA_SEARCH: "GET_DATA_SEARCH",
  GET_VERIFY_DATAINSIGHT: "GET_VERIFY_DATAINSIGHT",
  VERIFIED_DATAINSIGHT: "VERIFIED_DATAINSIGHT",
};

export const recoveryPredictorConstants = {
  GET_RECOVERY_PREDICTOR: "GET_RECOVERY_PREDICTOR",
  ADD_RECOVERY_PREDICTOR: "ADD_RECOVERY_PREDICTOR",
  UPDATE_RECOVERY_PREDICTOR: "UPDATE_RECOVERY_PREDICTOR",
};

export const fieldAgentConstants = {
  GET_FIELD_AGENT_LOANS: "GET_FIELD_AGENT_LOANS",
  UPDATE_FIELD_AGENT_LOAN: "UPDATE_FIELD_AGENT_LOAN",
  GET_FIELD_AGENT_ALL: "GET_FIELD_AGENT_ALL",
  FIELD_AGENT_ALLOCATED_COUNT: "FIELD_AGENT_ALLOCATED_COUNT",
  GET_ALLOCATION_RULE_LOANS: "GET_ALLOCATION_RULE_LOANS",
  DELETE_FIELD_AGENT: "DELETE_FIELD_AGENT",
};

export const revenueConstants = {
  GET_REVENUE_ALL: "GET_REVENUE_ALL",
  GET_CLIENT_REVENUE: "GET_CLIENT_REVENUE",
  ADD_REVENUE: "ADD_REVENUE",
  UPDATE_REVENUE: "UPDATE_REVENUE",
};

export const TenantConstant = {
  GET_TENANT_ALL: "GET_TENANT_ALL ",
};
